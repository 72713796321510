import type { GetAppManifest } from '@wix/editor-platform-sdk-types';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { SEARCH_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  EXPERIMENTS,
  IS_BLOG_MENU_SEARCH_ENABLED,
  IS_BLOG_MENU_SEARCH_ENABLED_MOBILE,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_MIGRATE_CUSTOM_FEED_ON_EDITOR_READY } from '@wix/communities-blog-experiments';
import translationsJson from '../../../translations.json';
import type { EditorAppContext } from '../../../types/editor-app-context.type';
import { blogAppDefId } from '../constants/apps';
import {
  APP_ACTION_EVENT,
  APP_ACTION_EVENTS,
  COMPONENT_ADDED_TO_STAGE,
  DELETE_BLOG_EVENT,
  INSTANCE_CHANGED,
  MANAGE_POSTS_EVENT,
  type EditorEvent,
} from '../constants/events';
import {
  NEW_BLOG_MAGIC_MIGRATION,
  NEW_BLOG_QUICK_MIGRATION,
} from '../constants/experiments';
import {
  MAGIC_MIGRATION_ID,
  MigrationId,
  OFFLINE_MAGIC_MIGRATION_ID,
  OFFLINE_PAID_POST_MIGRATION_ID,
} from '../constants/migrations';
import { TPA_PAGE_ID_BLOG, TPA_PAGE_ID_POST } from '../constants/tpa-pages';
import { AUTOPILOT_USER_UUID } from '../constants/users';
import { BLOG_WIDGET_ID, POST_WIDGET_ID } from '../constants/widgets';
import {
  displayProvisioningModal,
  initBiService,
  initProvision,
  openBlogPagesPanel,
  openPostPageSettings,
  setStyleParams,
} from './actions';
import { addBlocksWidget } from './add-blocks-widgets';
import getAppManifest from './app-manifest';
import concurrentEditing from './concurrent-editing';
import { migrateCustomFeed } from './custom-feed-migration/custom-feed-migration';
import { addEventListeners } from './event-listeners';
import experiments from './experiments';
import { getSiteMemberId } from './instance';
import { enableConfigurableLineClamping } from './line-clamping';
import maIntegrationKit from './ma-integration-kit';
import { getComponentRef } from './magic-migration/sdk-utils';
import { registerMemberPages } from './members-area/register-member-pages';
import menu from './menu';
import { migrateFeedDesignProps } from './migrate-feed-design-props';
import monitoring from './monitoring';
import oldBlogMigration from './old-blog-migration';
import pageService from './page';
import paidPostMigration from './paid-post-migration';
import { splitPostPage } from './post-page-split';
import { savePostPageStyle } from './post-page-style';
import translation from './translation';

const { translate } = translation;
const ADD_PANEL_DEEPLINK = 'addPanel';

async function untilTimeout(
  callback: () => Promise<any>,
  message: string,
  duration = 15000,
): Promise<void> {
  const error = await Promise.race([
    callback().then(() => undefined as void),
    new Promise<string>((resolve) =>
      setTimeout(() => resolve(message), duration),
    ),
  ]);

  if (error) {
    monitoring.reportError(message);
  }
}

const installSiteSearch = async (context: EditorAppContext) => {
  const { sdk, appToken, isADI } = context;

  if (isADI) {
    return Promise.resolve();
  }

  await concurrentEditing.withApproval(async () => {
    const isSearchInstalled = await sdk.document.tpa.isApplicationInstalled(
      appToken,
      { appDefinitionId: SEARCH_APP_ID },
    );

    if (isSearchInstalled) {
      return Promise.resolve();
    }

    const feedCompRef = await getComponentRef(sdk, BLOG_WIDGET_ID);
    const postPageCompRef = await getComponentRef(sdk, POST_WIDGET_ID);

    if (feedCompRef) {
      await sdk.document.tpa.setStyleParams(appToken, {
        compRef: feedCompRef,
        styleParams: [
          {
            type: 'boolean',
            key: IS_BLOG_MENU_SEARCH_ENABLED,
            param: { value: false },
          },
          {
            type: 'boolean',
            key: IS_BLOG_MENU_SEARCH_ENABLED_MOBILE,
            param: { value: false },
          },
        ],
      });
    }

    if (postPageCompRef) {
      await sdk.document.tpa.setStyleParams(appToken, {
        compRef: postPageCompRef,
        styleParams: [
          {
            type: 'boolean',
            key: IS_BLOG_MENU_SEARCH_ENABLED,
            param: { value: false },
          },
          {
            type: 'boolean',
            key: IS_BLOG_MENU_SEARCH_ENABLED_MOBILE,
            param: { value: false },
          },
        ],
      });
    }

    await untilTimeout(
      () =>
        sdk.document.tpa.add.application(blogAppDefId, {
          appDefinitionId: SEARCH_APP_ID,
        }),
      'Search installation timed out',
    );
  });
};

export const handleEditorReady = async (
  context: EditorAppContext,
  flowAPI: FlowAPI,
) => {
  const instance = context.essentials.createExperiments({});
  await experiments.conduct(instance);

  await Promise.all([
    experiments.conductSingle(instance, NEW_BLOG_QUICK_MIGRATION, 'old'),
    experiments.conductSingle(instance, NEW_BLOG_MAGIC_MIGRATION, 'old'),
  ]);
  const locale = await context.sdk.environment.getLocale();
  translation.init(translationsJson, locale);
  concurrentEditing.init(context);

  await monitoring.toMonitored(
    'register-member-pages',
    concurrentEditing.withApproval(() => registerMemberPages(context)),
  );

  if (context.isADI) {
    return Promise.resolve();
  }

  return Promise.all([
    concurrentEditing.withApproval(async () => {
      if (
        !experiments.isEnabled(EXPERIMENT_MIGRATE_CUSTOM_FEED_ON_EDITOR_READY)
      ) {
        return Promise.resolve();
      }

      await migrateCustomFeed(context);
    }),
    concurrentEditing.withApproval(() => menu.addPages(context)),
    concurrentEditing.withApproval(async () => {
      await migrateFeedDesignProps(context);
      await setStyleParams(context);
      await enableConfigurableLineClamping(context);
    }),
    addEventListeners(context),
    concurrentEditing.withApproval(async () => {
      if (
        flowAPI.experiments.enabled(EXPERIMENTS.ADD_BLOCKS_WIDGETS_IN_EDITOR)
      ) {
        console.log('## [Blocks Widgets] Adding blocks widgets...');
        console.log('## [Blocks Widgets] Done.', {
          postHeaderWidget: await addBlocksWidget(context, 'postHeader'),
        });
      }
    }),
  ]);
};

export const handleBlogInstalled = async (context: EditorAppContext) => {
  const {
    sdk,
    appToken,
    essentials,
    isADI,
    installMembersArea,
    silentInstallation,
  } = context;

  const instance = essentials.createExperiments({});

  await experiments.conduct(instance);
  await experiments.conductSingle(instance, NEW_BLOG_MAGIC_MIGRATION, 'old');

  const provisionInitialized = await initProvision(context);

  await initBiService(context);

  if (provisionInitialized) {
    try {
      await savePostPageStyle(context);
    } catch (e) {}
  }

  const shouldMigrateOldBlog = await oldBlogMigration.shouldMigrate({
    sdk,
    isADI,
    appToken,
  });
  if (shouldMigrateOldBlog) {
    return oldBlogMigration.migrate({ sdk }, { provisionInitialized });
  }

  if (installMembersArea === false) {
    await installSiteSearch(context);
    return Promise.resolve();
  }

  const isMAInstalled = await maIntegrationKit.isMembersAreaInstalled();
  const isMAModalEnabled = experiments.isEnabled(
    EXPERIMENTS.IS_MA_MODAL_ENABLED,
  );
  const showProvisioningModal =
    isMAModalEnabled && !isMAInstalled && !silentInstallation;
  const userId = await getSiteMemberId(sdk, appToken);
  const isAutopilot = userId === AUTOPILOT_USER_UUID;

  if (isAutopilot) {
    return Promise.resolve();
  }

  if (showProvisioningModal) {
    await displayProvisioningModal(context);
  }

  await installSiteSearch(context);
  await splitPostPage(context);
};

const openManagePostsDashboard = (context: EditorAppContext) =>
  context.sdk.editor.openDashboardPanel(context.appToken, {
    url: 'blog?referrer=app-manager',
    closeOtherPanels: false,
  });

const handleComponentAddedToSite = (context: EditorAppContext) =>
  concurrentEditing.withApproval(() => enableConfigurableLineClamping(context));

export const handleOnEvent = async (
  event: EditorEvent,
  context: EditorAppContext,
) => {
  if (!context.sdk) {
    return;
  }

  switch (event.eventType) {
    case MANAGE_POSTS_EVENT:
      return openManagePostsDashboard(context);
    case APP_ACTION_EVENT:
      switch (event.eventPayload?.actionId) {
        case APP_ACTION_EVENTS.CREATE_POST:
          context.sdk.editor.openDashboardPanel(context.appToken, {
            url: 'blog/create-post',
            closeOtherPanels: false,
          });
          break;
        case APP_ACTION_EVENTS.MANAGE_POSTS:
          openManagePostsDashboard(context);
          break;
        case APP_ACTION_EVENTS.OPEN_BLOG_PAGES_PANEL:
          await openBlogPagesPanel(context);
          break;
        case APP_ACTION_EVENTS.OPEN_POST_PAGE_SETTINGS:
          openPostPageSettings(
            context,
            translate('app-manifest.app-descriptor.post-settings-title'),
          );
          break;
        case APP_ACTION_EVENTS.OPEN_BLOG_ADD_PANEL:
          context.sdk.editor.deeplink.show(context.appToken, {
            type: ADD_PANEL_DEEPLINK,
            params: [blogAppDefId],
          });
          break;
        default:
          break;
      }
      break;

    case DELETE_BLOG_EVENT:
      return monitoring.toMonitored('delete-blog', deleteBlog(context));
    case COMPONENT_ADDED_TO_STAGE:
      return handleComponentAddedToSite(context);
    case INSTANCE_CHANGED:
      context.instance = event.eventPayload?.instance;
      break;
    default:
      break;
  }
};

async function deleteBlog(context: EditorAppContext) {
  const blogPage = await pageService.find({
    ...context,
    tpaPageId: TPA_PAGE_ID_BLOG,
  });

  if (!blogPage?.id) {
    const postPage = await pageService.find({
      ...context,
      tpaPageId: TPA_PAGE_ID_POST,
    });

    if (!postPage?.id) {
      return;
    }

    return context.sdk.document.pages.remove(context.appToken, {
      pageRef: { id: postPage.id, type: 'DESKTOP' },
    });
  }

  return context.sdk.document.pages.remove(context.appToken, {
    pageRef: { id: blogPage.id, type: 'DESKTOP' },
  });
}

export const handleGetAppManifest = ({
  sdk,
  appToken,
}: EditorAppContext): ReturnType<GetAppManifest> => {
  if (!sdk) {
    return {};
  }

  return getAppManifest(sdk.info.getSdkVersion(appToken).scriptSrc, appToken);
};

export const handleMigrateAction = (
  context: EditorAppContext,
  migrationId: MigrationId,
) => {
  switch (migrationId) {
    case OFFLINE_PAID_POST_MIGRATION_ID:
      return paidPostMigration.migrateOffline(context);
    case OFFLINE_MAGIC_MIGRATION_ID:
      return oldBlogMigration.migrateOffline(context);
    case MAGIC_MIGRATION_ID:
      return oldBlogMigration.migrate(context, { skipComponentMapping: true });
    default:
      return Promise.resolve();
  }
};
