/**
 * Experiments created on Petri should be listed here.
 *
 * #### Scopes
 *
 * Any new created specs should be conducted via centralized conduction.
 *
 * This means that they should be created with the following scopes:
 *
 * |            | Scope                                              |
 * | :-         | :-                                                 |
 * | Backoffice | `bm-apps-14bcded7-0066-7c35-14d7-466cb3f09103`     |
 * | Viewer     | `viewer-apps-14bcded7-0066-7c35-14d7-466cb3f09103` |
 */
export var EXPERIMENTS = {
    USE_MEDIA: 'specs.wixBlog.useMedia',
    USE_WARMUP_STATE_IN_FEED: 'specs.wixBlog.UseWarmupStateInFeed',
    USE_WARMUP_STATE_IN_POST_LIST: 'specs.wixBlog.UseWarmupStateInPostList',
    IS_MA_MODAL_ENABLED: 'specs.wixBlog.isMAModalEnabled',
    /**
     * https://bo.wix.com/petri/experiments/2048833
     *
     * Adds blocks-based widgets in Editor & used for local development only
     **/
    ADD_BLOCKS_WIDGETS_IN_EDITOR: 'specs.wixBlog.addBlocksWidgetsInEditor',
};
