import { EditorAppContext } from '../../../types/editor-app-context.type';
import { TPA_PAGE_ID_POST } from '../constants/tpa-pages';

export async function addBlocksWidget(
  context: EditorAppContext,
  widgetName: keyof typeof blocksWidgets,
) {
  const config = blocksWidgets[widgetName];
  const pageRef = await context.sdk.tpa.getPageRefByTPAPageId(
    context.appToken,
    { tpaPageId: TPA_PAGE_ID_POST },
  );

  const presetIds = {
    layout: config.defaultPresetId,
    style: config.defaultPresetId,
  };

  return context.sdk.application.appStudioWidgets.addWidget(context.appToken, {
    installationType: 'closed',
    widgetId: config.widgetId,
    containerRef: { id: pageRef.id, type: 'DESKTOP' },
    layout: { x: 0, y: 0 },
    dimensionsByDevices: {
      desktop: {
        width: { type: 'px', value: 600 },
        height: { type: 'px', value: 600 },
      },
    },
    layouts: {
      componentLayout: {
        type: 'ComponentLayout',
        hidden: false,
        height: { type: 'auto' },
        minHeight: { type: 'px', value: 600 },
        width: { type: 'px', value: 600 },
      },
      itemLayout: {
        id: '',
        alignSelf: 'center',
        justifySelf: 'center',
        type: 'GridItemLayout',
        gridArea: { rowStart: 1, rowEnd: 2, columnStart: 1, columnEnd: 2 },
        margins: {
          top: { type: 'px', value: 0 },
          left: { type: 'px', value: 0 },
        },
      },
      containerLayout: {
        type: 'GridContainerLayout',
        columns: [{ type: 'fr', value: 1 }],
        rows: [{ type: 'fr', value: 1 }],
      },
    },
    presetIds,
    scopedPresets: { desktop: presetIds, mobile: presetIds },
  });
}

/**
 * **How to get widgetId:**
 *
 * 1. Add `&experiments=rightClickDebug` in Blocks editor.
 * 2. Right click on the widget and select "Debug > BoB App > Get DevCenter widget id"
 * (or copy the "id" field from `.component.json` file)
 *
 * **How to get preset ids:**
 *
 * 1. Add `&experiments=rightClickDebug` in Blocks editor.
 * 2. Right click on the widget and select "Debug > BoB App > Get Presets"
 **/
const blocksWidgets = {
  postHeader: {
    widgetId: '5fdc6c03-080d-4872-b567-24146c82fae5',
    defaultPresetId: 'variants-lj4113r11',
  },
} as const;
